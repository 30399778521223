import 'custom-event-polyfill';
import touchevents from './detectTouch';
import './resizeHandler';
import './vh';

export const HTML = document.documentElement;
export const BODY = document.body;
export const BREAKPOINTS = {
  xs: 0,
  phone: 600,
  tablet: 768,
  desktop: 960,
  laptop: 1280,
  widescreen: 1440,
};

/*
 * Utility module
 */
export const lib = {
    hasClass: function(el,cls) {
      return el && el.className ? el.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)')) : false;
    },
    addClass: function(el,cls) {
      if (el && !this.hasClass(el,cls)) el.className += " "+cls;
    },
    removeClass: function(el,cls) {
      if (el && this.hasClass(el,cls)) {el.className=el.className.replace(new RegExp('(\\s|^)'+cls+'(\\s|$)'),' ');}
    },
    extend: function(obj) {
      for(var i = 1; i < arguments.length; i++) {
        for(var p in arguments[i]) {
          if(arguments[i].hasOwnProperty(p)) {
            obj[p] = arguments[i][p];
          }
        }
      }
      return obj;
    },
    each: function(obj, callback) {
      var property, len;
      if(typeof obj.length === 'number') {
        for(property = 0, len = obj.length; property < len; property++) {
          if(callback.call(obj[property], property, obj[property]) === false) {
            break;
          }
        }
      } else {
        for(property in obj) {
          if(obj.hasOwnProperty(property)) {
            if(callback.call(obj[property], property, obj[property]) === false) {
              break;
            }
          }
        }
      }
    },
    event: (function() {
      var fixEvent = function(e) {
        e = e || window.event;
        if(e.isFixed) return e; else e.isFixed = true;
        if(!e.target) e.target = e.srcElement;
        e.preventDefault = e.preventDefault || function() {this.returnValue = false;};
        e.stopPropagation = e.stopPropagation || function() {this.cancelBubble = true;};
        return e;
      };
      return {
        add: function(elem, event, handler) {
          if(!elem.events) {
            elem.events = {};
            elem.handle = function(e) {
              var ret, handlers = elem.events[e.type];
              e = fixEvent(e);
              for(var i = 0, len = handlers.length; i < len; i++) {
                if(handlers[i]) {
                  ret = handlers[i].call(elem, e);
                  if(ret === false) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }
              }
            };
          }
          if(!elem.events[event]) {
            elem.events[event] = [];
            if(elem.addEventListener) elem.addEventListener(event, elem.handle, false);
            else if(elem.attachEvent) elem.attachEvent('on'+event, elem.handle);
          }
          elem.events[event].push(handler);
        },
        remove: function(elem, event, handler) {
          var handlers = elem.events[event];
          for(var i = handlers.length - 1; i >= 0; i--) {
            if(handlers[i] === handler) {
              handlers.splice(i,1);
            }
          }
          if(!handlers.length) {
            delete elem.events[event];
            if(elem.removeEventListener) elem.removeEventListener(event, elem.handle, false);
            else if(elem.detachEvent) elem.detachEvent('on'+event, elem.handle);
          }
        }
      };
    }()),
    queryElementsBySelector: function(selector, scope) {
      scope = scope || document;
      if(!selector) return [];
      if(selector === '>*') return scope.children;
      if(typeof document.querySelectorAll === 'function') {
        return scope.querySelectorAll(selector);
      }
      var selectors = selector.split(',');
      var resultList = [];
      for(var s = 0; s < selectors.length; s++) {
        var currentContext = [scope || document];
        var tokens = selectors[s].replace(/^\s+/,'').replace(/\s+$/,'').split(' ');
        for (var i = 0; i < tokens.length; i++) {
          token = tokens[i].replace(/^\s+/,'').replace(/\s+$/,'');
          if (token.indexOf('#') > -1) {
            var bits = token.split('#'), tagName = bits[0], id = bits[1];
            var element = document.getElementById(id);
            if (element && tagName && element.nodeName.toLowerCase() != tagName) {
              return [];
            }
            currentContext = element ? [element] : [];
            continue;
          }
          if (token.indexOf('.') > -1) {
            var bits = token.split('.'), tagName = bits[0] || '*', className = bits[1], found = [], foundCount = 0;
            for (var h = 0; h < currentContext.length; h++) {
              var elements;
              if (tagName == '*') {
                elements = currentContext[h].getElementsByTagName('*');
              } else {
                elements = currentContext[h].getElementsByTagName(tagName);
              }
              for (var j = 0; j < elements.length; j++) {
                found[foundCount++] = elements[j];
              }
            }
            currentContext = [];
            var currentContextIndex = 0;
            for (var k = 0; k < found.length; k++) {
              if (found[k].className && found[k].className.match(new RegExp('(\\s|^)'+className+'(\\s|$)'))) {
                currentContext[currentContextIndex++] = found[k];
              }
            }
            continue;
          }
          if (token.match(/^(\w*)\[(\w+)([=~\|\^\$\*]?)=?"?([^\]"]*)"?\]$/)) {
            var tagName = RegExp.$1 || '*', attrName = RegExp.$2, attrOperator = RegExp.$3, attrValue = RegExp.$4;
            if(attrName.toLowerCase() == 'for' && this.browser.msie && this.browser.version < 8) {
              attrName = 'htmlFor';
            }
            var found = [], foundCount = 0;
            for (var h = 0; h < currentContext.length; h++) {
              var elements;
              if (tagName == '*') {
                elements = currentContext[h].getElementsByTagName('*');
              } else {
                elements = currentContext[h].getElementsByTagName(tagName);
              }
              for (var j = 0; elements[j]; j++) {
                found[foundCount++] = elements[j];
              }
            }
            currentContext = [];
            var currentContextIndex = 0, checkFunction;
            switch (attrOperator) {
              case '=': checkFunction = function(e) { return (e.getAttribute(attrName) == attrValue) }; break;
              case '~': checkFunction = function(e) { return (e.getAttribute(attrName).match(new RegExp('(\\s|^)'+attrValue+'(\\s|$)'))) }; break;
              case '|': checkFunction = function(e) { return (e.getAttribute(attrName).match(new RegExp('^'+attrValue+'-?'))) }; break;
              case '^': checkFunction = function(e) { return (e.getAttribute(attrName).indexOf(attrValue) == 0) }; break;
              case '$': checkFunction = function(e) { return (e.getAttribute(attrName).lastIndexOf(attrValue) == e.getAttribute(attrName).length - attrValue.length) }; break;
              case '*': checkFunction = function(e) { return (e.getAttribute(attrName).indexOf(attrValue) > -1) }; break;
              default : checkFunction = function(e) { return e.getAttribute(attrName) };
            }
            currentContext = [];
            var currentContextIndex = 0;
            for (var k = 0; k < found.length; k++) {
              if (checkFunction(found[k])) {
                currentContext[currentContextIndex++] = found[k];
              }
            }
            continue;
          }
          tagName = token;
          var found = [], foundCount = 0;
          for (var h = 0; h < currentContext.length; h++) {
            var elements = currentContext[h].getElementsByTagName(tagName);
            for (var j = 0; j < elements.length; j++) {
              found[foundCount++] = elements[j];
            }
          }
          currentContext = found;
        }
        resultList = [].concat(resultList,currentContext);
      }
      return resultList;
    },
    trim: function (str) {
      return str.replace(/^\s+/, '').replace(/\s+$/, '');
    },
    bind: function(f, scope, forceArgs){
      return function() {return f.apply(scope, typeof forceArgs !== 'undefined' ? [forceArgs] : arguments);};
    }
  };

/*
  Breakpoints utility function
  @params: point - {number} - breakpoint to check, e.g. BREAKPOINTS.tablet
  @params: desktopFirst - {boolean} - if you need desktop first

  @return: boolean

  Usage

  import 2 itilities
  import {BREAKPOINTS, breakpoint} from 'Utils/global';

  window.addEventListener('resize', () => {
    if (breakpoint(BREAKPOINTS.desktop)) {
      console.log('IS DESKTOP')
    } else {
      console.log('IS MOBILE')
    }
  })
*/
export const breakpoint = (point, desktopFirst = false) => {
  if (desktopFirst) {
    return window.innerWidth < point;
  }

  return window.innerWidth >= point;
};

/*
  Detect touchevents
  @params: {obj} - window
  @return: boolean
*/
export const isTouch = (obj = window) => touchevents(obj);

/*
  "jQuery like" ready function:
  Usage:

  import ready from 'Utils/global';
  ready(() => init());
*/

export default Document.prototype.ready = (fn) => {
  if (fn && typeof fn === 'function') {
    document.addEventListener('DOMContentLoaded', () => {
      if (document.readyState === 'interactive' || document.readyState === 'complete') {
        return fn();
      }
    });
  }
};

/*
  Publish custom event
  Params:

  {eventName}: String - the name of custom event. Better to use as a variable or constant not to mess names
  {data}: Object - custom event information, e.g. node element, whatever. Accessible via event
  {once}: Bool - trigger only once or every time when called
  Exmaple:

  import {ev} from 'Utils/global';

  const eventName = 'PopupToggle';

  popup.on('click', () => {
    ev(eventName, {
      popup: this,
    })
  })

  document.addEventListener(eventName, event => {
    // this is data that we pass into custom event
    const eventData = event.detail;
    const popupInstance = eventData.popup;
  })
*/

export const ev = (eventName, data, target = document) => {
  const e = new CustomEvent(eventName, { detail: data });
  target.dispatchEvent(e);
};

/* @return boolean (true=IE) */
export const isIE = () => {
  return /*@cc_on!@*/ false || !!document.documentMode;
};
