<template>
  <div>
    <a class="btn-primary cursor-pointer" @click="showModal = true">Join Our Mailing List</a>
    <b-modal v-model="showModal" centered hide-footer hide-header size="lg" hide-backdrop lazy>
      <iframe src="https://cdn.forms-content.sg-form.com/77945601-3ebb-11eb-8184-aec96119bf4a" style="width: 100%; min-height: 400px; border: none"/>
    </b-modal>
  </div>
</template>

<script>
export default  {
  data() {
    return {
      showModal: false
    }
  }
}
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>