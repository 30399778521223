<template>
    <div>
        <b-button variant="link" @click="show = true">
            {{ buttonTitle }}
        </b-button>
        <b-modal :title="title" v-model="show" ok-only>
            <div v-html="content"></div>
        </b-modal>
    </div>
</template>
<script>
export default {
  props: ['title', 'buttonTitle', 'content'],
  data() {
    return {
      show: false
    }
  }
}
</script>