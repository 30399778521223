function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const images = importAll(require.context('../images', true, /\.(png|jpe?g|svg)$/));

class AssetLoader {
}

AssetLoader.imagePath = function (name) {
  return images[name];
};

AssetLoader.imagesInFolder = function (folder) {
  return Object.keys(images).filter(img => img.startsWith(folder)).map(img => images[img]);
};

export default AssetLoader