<template>
    <div class="poc-file-input">
        <b-form-file :accept="accept"
                     type="file"
                     class="d-none"
                     :name="paramName"
                     :multiple="multiple === true"
                     ref="fileInput"
                     :value="value"
                     @input="updateFile"/>
        <div v-if="hasFile" class="bg-light py-1 px-2">
            <b-row no-gutters class="text-black-50">
                <b-col cols="auto"><i class="fa fa-paperclip"/></b-col>
                <b-col class="px-2 text-truncate">{{ fileName }}</b-col>
                <b-col cols="auto"><i class="fa fa-times cursor-pointer" @click="$refs.fileInput.reset()"/></b-col>
            </b-row>
        </div>
        <div v-else>
            <div @click="selectFile" class="btn btn-outline btn-sm btn-rounded" v-if="!hasButtonSlot">
                {{ label }}
            </div>
            <div v-else @click="selectFile">
                <slot name="button-slot"></slot>
            </div>

        </div>
    </div>
</template>

<script>
  export default {
    props: ['paramName', 'label', 'accept', 'value', 'multiple'],

    data: function () {
      return {
        file: this.value
      }
    },

    computed: {

      hasFile() {
        if (Array.isArray(this.file) && this.file.length === 0) {
          return false
        }

        return !!this.file
      },

      hasButtonSlot: function () {
        return !!this.$slots['button-slot']
      },

      fileName() {
        if (Array.isArray(this.file)) {
          return `${this.file.length} files`
        } else {
          return this.file.name
        }
      }
    },

    methods: {
      updateFile: function(file) {
        this.file = file;
        this.$emit('input', file)
      },

      selectFile: function () {
        this.$refs.fileInput.$el.childNodes[0].click()
      }
    }
  }
</script>