import Vue from 'vue/dist/vue.esm'
import BootstrapVue from 'bootstrap-vue'
import GenericModal from "../../../javascripts/vue/components/Common/GenericModal";
import GenericFormModal from "../../../javascripts/vue/components/Common/GenericModal";
import SendgridMailForm from "../../../javascripts/vue/components/Public/SendgridMailForm";
import RequestQuoteApp from "../../../javascripts/vue/components/RequestQuote/RequestQuoteApp.vue";

Vue.use(BootstrapVue);

Vue.component('generic-modal', GenericModal);
Vue.component('generic-form-modal', GenericFormModal);
Vue.component('sendgrid-mail-form', SendgridMailForm);
Vue.component('request-quote-app', RequestQuoteApp);

const initializeGenericVue = function () {
  const nodeList = document.querySelectorAll('[data-behavior="vue"]');
  Array.from(nodeList).forEach(element => {
    new Vue({
      el: element
    })
  })
}

const initializeWrapVue = function () {
  const nodeList = document.querySelectorAll('[data-behavior="wrapvue"]');
  if (Array.from(nodeList).length === 0) return;

  Array.from(nodeList).forEach(element => {
    new Vue({
      el: element
    })
  })
}

document.addEventListener('DOMContentLoaded', function() {
  initializeGenericVue()
  initializeWrapVue()
});



