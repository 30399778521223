<template>
  <div>
    <slot :handleTriggerButton="handleTriggerButton">
      <button class="btn btn-primary btn-rounded" @click="handleTriggerButton">
        Request Quote
      </button>
    </slot>

    <b-modal :size="step === 'finish' ? 'md' : 'lg'" v-model="show" hide-footer hide-header #default="{ close }">
      <div class="tw-p-8">
        <div class="tw-flex tw-justify-between">
          <h4>
            <span v-if="step !== 'finish'">
              {{ headerLabel(step) }}
            </span>
          </h4>
          <button @click="close" class="btn-reset">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="tw-mt-8">
          <template v-if="step === 'about'">
            <form @submit="handleAboutSubmit" ref="aboutForm">
              <div class="tw-font-medium">Tell us about yourself</div>

              <div class="tw-mt-6">
                <b-form-group label="Name" label-class="label-required">
                  <b-input type="text" v-model="name" required/>
                </b-form-group>

                <b-form-group label="Email" label-class="label-required">
                  <b-input type="email" v-model="email" required/>
                </b-form-group>

                <b-form-group label="Company">
                  <b-input type="text" v-model="company"/>
                </b-form-group>
              </div>

              <div class="tw-flex tw-justify-end tw-pt-8">
                <button class="tw-btn-primary tw-btn-rounded tw-btn-lg">
                  Next
                </button>
              </div>
            </form>
          </template>

          <template v-if="step === 'project'">
            <form @submit="handleProjectSubmit" ref="projectForm">
              <div class="tw-mt-8">

                <b-form-group label="Type of project" label-class="text-truncate label-required">
                  <button v-for="type in projectTypes" class="btn-reset tw-btn-rounded tw-btn-lg tw-mr-4 tw-mb-4"
                          @click="selectProjectType(type.value)"
                          type="button"
                          :class="{
                        'tw-btn-primary': selectedProjectTypes[type.value],
                        'tw-btn-outline': !selectedProjectTypes[type.value]
                        }">
                    {{ type.label }}
                  </button>
                  <div class="text-danger tw-text-xs" v-if="projectTypeError">Please select a project type</div>
                </b-form-group>

              </div>

              <div class="tw-mt-8 tw-flex tw-flex-col tw-gap-4">
                <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                  <b-form-group label="Budget Range" label-class="label-required">
                    <div class="dropdown w-100">
                      <button type="button"
                              class="btn btn-outline d-flex align-items-center justify-content-between w-100 tw-h-[50px]"
                              data-toggle="dropdown">
                        <span>{{ budgetRange ? '' : '$' }}{{ budgetRange }}</span>
                        <i class="fas fa-caret-down"></i>
                      </button>
                      <div class="dropdown-menu w-100">
                        <button type="button" class="dropdown-item py-2" v-for="option in budgetRangeOptions"
                                :key="option"
                                @click="budgetRange = option">
                          <span class="tw-flex tw-justify-between tw-items-center tw-text-sm">
                            <span>{{ option }}</span>
                            <i class="fas fa-check-circle text-primary"
                               :class="{'invisible': budgetRange !== option}"></i>
                          </span>
                        </button>
                      </div>
                      <div class="text-danger tw-text-xs" v-if="budgetRangeError">Please select a budget range</div>
                    </div>
                  </b-form-group>

                  <b-form-group label="Target Delivery Date" label-class="label-required">
                    <b-input type="date" v-model="startDate" required class="tw-relative"/>
                  </b-form-group>
                </div>

                <b-form-group label="Location" label-class="label-required">
                  <b-input type="text" v-model="location" required/>
                </b-form-group>
              </div>


              <div class="tw-flex tw-justify-between tw-pt-8">
                <button type="button"
                        @click="handleBackProject"
                        class="tw-btn-outline tw-btn-rounded tw-btn-lg">
                  Back
                </button>
                <button class="tw-btn-primary tw-btn-rounded tw-btn-lg">
                  Next
                </button>
              </div>
            </form>
          </template>

          <template v-if="step === 'brief'">
            <form @submit="handleFinalSubmit" ref="briefForm">

              <div class="alert alert-danger" v-if="submitError">
                {{ submitError }}
              </div>

              <div class="tw-mt-6">
                <b-form-group label="Additional Notes">
                  <b-textarea type="text" v-model="notes" rows="3"/>
                </b-form-group>

                <b-form-group label="Or attach a brief">
                  <file-input accept="application/pdf" label="CHANGE ASSET" v-model="file" class="mt-2">
                    <template #button-slot>
                      <div
                        ref="dropzone"
                        class="tw-cursor-pointer
                  tw-border tw-border-dashed tw-border-gray-300 tw-bg-gray-50
                  tw-rounded-lg tw-h-20 tw-flex tw-flex-col tw-items-center tw-justify-center">
                        <div class="tw-text-gray-400"><span class="text-primary">Upload</span> pdf file here</div>
                      </div>
                    </template>
                  </file-input>
                </b-form-group>

                <div id="recaptcha-element"></div>
              </div>

              <div class="tw-flex tw-justify-between tw-pt-8">
                <button type="button"
                        @click="handleBackSubmit"
                        class="tw-btn-outline tw-btn-rounded tw-btn-lg">
                  Back
                </button>
                <button class="tw-btn-primary tw-btn-rounded tw-btn-lg" :disabled="submitting">
                  Submit
                </button>
              </div>
            </form>
          </template>

          <template v-if="step === 'finish'">
            <div class="tw-flex tw-flex-col tw-gap-8 tw-items-center tw-max-w-lg tw-mx-auto">

              <img :src="congratsImage()" class="tw-mt-6 tw-mb-8" alt="congrats image"/>

              <div class="tw-text-3xl tw-font-bold">Project Request Sent!</div>

              <div class="tw-text-gray-500 tw-text-center">
                Thanks, we received your request. Our team will review and get back to you shortly!
              </div>
            </div>
          </template>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FileInput from '../Common/FileInput'
import AssetLoader from "../../../AssetLoader";

export default {
  components: {FileInput},
  props: {
    siteKey: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      show: false,
      step: 'about', // about, project, brief, finish
      name: '',
      email: '',
      company: '',
      budgetRange: null,
      budgetRangeError: false,
      projectTypeError: false,
      selectedProjectTypes: {},
      startDate: null,
      location: null,
      notes: null,
      file: null,
      submitError: null,
      submitting: false,
      recaptchaWidgetId: null
    }
  },

  methods: {
    selectProjectType(type) {
      this.selectedProjectTypes = {
        ...this.selectedProjectTypes,
        [type]: !this.selectedProjectTypes[type]
      }
    },

    reset() {
      this.step = 'about'
      this.name = ''
      this.email = ''
      this.company = ''
      this.budgetRange = null
      this.budgetRangeError = false
      this.projectTypeError = false
      this.selectedProjectTypes = {}
      this.startDate = null
      this.location = null
      this.notes = null
      this.file = null
      this.submitError = null
      this.submitting = false
    },

    congratsImage() {
      return AssetLoader.imagePath(`congrats.png`);
    },

    handleTriggerButton() {
      this.show = true
    },

    handleAboutSubmit(evt) {
      evt.preventDefault();
      if (this.$refs.aboutForm.reportValidity()) {
        this.step = 'project'
      }
    },

    handleProjectSubmit(evt) {
      evt.preventDefault();
      this.budgetRangeError = false
      this.projectTypeError = false

      if (!this.budgetRange) {
        this.budgetRangeError = true
      }

      if (!this.projectTypeValues.length) {
        this.projectTypeError = true
      }

      const hasProjectErrors = this.budgetRangeError || this.projectTypeError

      if (!hasProjectErrors && this.$refs.projectForm.reportValidity()) {
        this.step = 'brief'
      }
    },

    handleBackSubmit(evt) {
      evt.preventDefault();
      document.getElementById('recaptcha-element').innerHTML = '';
      this.step = 'project'
    },

    handleBackProject(evt) {
      evt.preventDefault();
      this.step = 'about'
    },

    makeFormData(recaptchaResponse) {
      const formData = new FormData()
      formData.append('g-recaptcha-response-data', recaptchaResponse)
      formData.append('project_quote[name]', this.name)
      formData.append('project_quote[email]', this.email)
      formData.append('project_quote[company]', this.company)
      formData.append('project_quote[budget]', this.budgetRange)
      this.projectTypeValues.forEach((value, index) => {
        formData.append(`project_quote[project_types][]`, value);
      })
      formData.append('project_quote[date]', this.startDate)
      formData.append('project_quote[location]', this.location)
      formData.append('project_quote[notes]', this.notes)
      formData.append('project_quote[brief_data]', this.file)
      return formData
    },

    async handleFinalSubmit(evt) {
      evt.preventDefault();

      const recaptchaResponse = window.grecaptcha.getResponse()

      if (!window.grecaptcha || !recaptchaResponse) {
        this.submitError = "Please complete the reCAPTCHA"
        return
      }

      this.submitError = null
      this.submitting = true

      const formData = this.makeFormData(recaptchaResponse)

      try {
        await this.$http.post('/api/project_quotes', formData)
        this.step = 'finish'
      } catch (e) {
        this.submitError = "There was an error submitting your request. Please try again later."
      }
    },

    headerLabel(step) {
      switch (step) {
        case 'about':
          return 'Request a Project Quote'
        case 'project':
          return 'Tell us about your project'
        case 'brief':
          return 'Anything else we should know?'
        case 'finish':
          return 'Project Request Sent!'
      }
    },

    renderRecaptcha() {
      setTimeout(() => {
        const element = document.getElementById('recaptcha-element')
        if (!element) {
          console.log('no captcha element');
          return
        }

        if (window.grecaptcha && window.grecaptcha.render) {
          console.log('rendering recaptcha');

          // Create a new element
          const newElement = document.createElement('div');
          const recaptchaElementId = `recaptcha-${Date.now()}`;
          newElement.setAttribute('id', recaptchaElementId);

          // Append the new element to the parent container
          const parentElement = element
          parentElement.innerHTML = ''; // Clear previous reCAPTCHA, if any
          parentElement.appendChild(newElement);

          // Render reCAPTCHA
          window.grecaptcha.render(recaptchaElementId, {'sitekey': this.siteKey});
        }
      }, 500)

    }
  },

  watch: {
    projectType() {
      this.projectTypeError = false
    },
    budgetRange() {
      this.budgetRangeError = false
    },
    budgetRangeOptions() {
      if (!this.budgetRangeOptions.includes(this.budgetRange)) {
        this.budgetRange = null
      }
    },

    step(newValue) {
      if (newValue === 'brief') {
        this.renderRecaptcha()
      }
    },

    show(newValue) {
      if (newValue) {
        this.renderRecaptcha()
      }
    }
  },

  computed: {
    projectTypes() {
      return [
        {value: 'photo_shoot', label: 'Photo Shoot'},
        {value: 'video_shoot', label: 'Video Shoot'},
        {value: 'live_event', label: 'Live Event'},
        {value: 'head_shots', label: 'Head Shots'},
        {value: 'workshop', label: 'Workshop'},
        {value: 'speaking', label: 'Speaking'},
      ]
    },
    budgetRangeOptions() {
      const live_events = [
        "Less than $10.000",
        "$10.000 - $25.000",
        "$25.000 - $50.000",
        "$50.000 - $100.000",
        "Over $100.000"
      ]

      const video = [
        "$25.000 - $75.000",
        "$75.000 - $150.000",
        "$150.000 - $250.000",
        "Over $250.000"
      ]

      const photo = [
        "Less than $25.000",
        ...video
      ]


      if (this.projectTypeValues.includes('video_shoot')) {
        return video
      }

      if (this.projectTypeValues.includes('photo_shoot')) {
        return photo
      }

      if (this.projectTypeValues.includes('live_event')) {
        return live_events
      }

      return live_events

    },

    projectTypeValues() {
      return Object.keys(this.selectedProjectTypes).filter(key => this.selectedProjectTypes[key] === true)
    }
  }
}
</script>
