// Changes to originals:
// - rewrote imports to be in the correct case in js files
// - removed console logs
// - added $fa-font-path to icomoon imports
require("../custom/js/app")
require("../custom/overrides/js/initializers")

// If i don't put this here, the images don't load in the webpack manifest
const images = require.context('../custom/images', true)
const imagePath = (name) => images(name, true)

import '../stylesheets/custom_application.scss'
